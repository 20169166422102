import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ResponsiveValue,
  useDisclosure,
} from "@chakra-ui/react";
import { JSXElementConstructor, ReactElement } from "react";

interface Props {
  triggerButtonSize?: ResponsiveValue<(string & {}) | "sm" | "md" | "lg" | "xs"> | undefined;
  triggerButtonVariant?:
    | ResponsiveValue<"link" | (string & {}) | "outline" | "solid" | "ghost" | "unstyled">
    | undefined;
  triggerButtonColor?: string;
  title?: string;
  body: React.ReactNode;
  customFooter?: React.ReactNode;
  triggerTitle?: React.ReactNode;
  buttonCloseName?: string;
  triggerButtonIcon?: ReactElement<any, string | JSXElementConstructor<any>> | undefined;
  buttonSubmitName?: string;
  size?: string;
  showSubmitButton?: boolean;
  isOpen?: boolean;
  loading?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  showCloseButton?: boolean;
  buttonAction?: () => void;
  showTriggerButton?: boolean;
  showFooter?: boolean;
}

function CustomModal({
  triggerButtonSize,
  triggerButtonVariant,
  triggerButtonColor,
  triggerButtonIcon,
  showTriggerButton = true,
  title,
  body,
  triggerTitle = "Continue",
  buttonCloseName,
  buttonSubmitName,
  customFooter,
  showSubmitButton = true,
  showCloseButton = true,
  size,
  loading,
  isOpen: externalIsOpen,
  onClose: externalOnClose,
  onOpen: externalOnOpen,
  buttonAction,
  showFooter = true,
}: Props) {
  const internalDisclosure = useDisclosure();
  const isOpen = externalIsOpen !== undefined ? externalIsOpen : internalDisclosure.isOpen;
  const onClose = externalOnClose || internalDisclosure.onClose;
  const onOpen = externalOnOpen || internalDisclosure.onOpen;

  return (
    <Box zIndex={9999}>
      {showTriggerButton && (
        <Button
          isLoading={loading}
          size={triggerButtonSize ? triggerButtonSize : "md"}
          variant={triggerButtonVariant ? triggerButtonVariant : undefined}
          fontSize={"sm"}
          onClick={onOpen}
          color={triggerButtonColor ? triggerButtonColor : undefined}
          leftIcon={triggerButtonIcon ? triggerButtonIcon : undefined}
        >
          {triggerTitle}
        </Button>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size={size}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{body}</ModalBody>

          {showFooter && (
            <ModalFooter>
              {showCloseButton && (
                <Button variant="ghost" mr={3} onClick={onClose}>
                  {buttonCloseName ? buttonCloseName : "Close"}
                </Button>
              )}
              {customFooter && customFooter}
              {showSubmitButton && (
                <Button
                  isLoading={loading}
                  type="submit"
                  onClick={() => {
                    buttonAction ? buttonAction() : null;
                    onClose();
                  }}
                >
                  {buttonSubmitName ? buttonSubmitName : "Save"}
                </Button>
              )}
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default CustomModal;

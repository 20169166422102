"use client";

import API from "@/services/API.service";
import { Plan } from "@prisma/client";

export async function createPlanFetch(newPlan: Plan) {
  const response = await API.post(`/api/admin/plan`, {
    body: JSON.stringify(newPlan),
  });

  if (response.success !== true) {
    throw new Error(response.message);
  }

  return response.plan;
}

export async function updatePlanFetch(newPlan: Plan) {
  const response = await API.put(`/api/admin/plan/${newPlan.id}`, {
    body: JSON.stringify(newPlan),
  });

  if (response.success !== true && response.plan === undefined) {
    throw new Error(response.message);
  }

  return response.plan;
}

export async function deletePlanFetch(planId: string) {
  const response = await API.delete(`/api/admin/plan/${planId}`);

  if (response.success !== true) {
    throw new Error(response.message);
  }

  return true;
}

export async function getVisiblePlansAPI() {
  const response = await API.get(`/api/plans?visible=true&sortBy=rank&includeStripeData=true`);

  if (response.success !== true) {
    throw new Error(response.message);
  }

  return response.plans;
}

import { Tooltip, Typography } from "antd";
import { format } from "date-fns";
import { isNil } from "lodash";
import React from "react";

const Text = Typography.Text;

interface Props {
  date: number | string | bigint | null | undefined;
  dateFormat?: string | undefined;
  dateTimeFormat?: string | undefined;
}

const DateDisplay = ({ date, dateFormat, dateTimeFormat }: Props) => {
  const _dateValue = typeof date === "string" ? date : Number(date);
  const _date = new Date(_dateValue);
  const _dateTimeFormat = !isNil(dateTimeFormat) ? dateTimeFormat : "MM/dd/yyyy hh:mm:ss a";
  const _dateFormat = !isNil(dateFormat) ? dateFormat : "MM/dd/yyyy";

  return (
    <Tooltip title={format(_date, _dateTimeFormat)}>
      <Text>{format(_date, _dateFormat)}</Text>
    </Tooltip>
  );
};

export default DateDisplay;

"use client";

import { deleteSystem, updateSystem } from "@/app/apiCalls/projects/projectsCRUD";
import { CurrentUserContext } from "@/app/contexts/currentUserContext";

import { useMultiSelect } from "@/hooks/useMultiSelect";
import { FilterFilled } from "@ant-design/icons";

import { Companies, Customizations, Users } from "@prisma/client";
import {
  Badge,
  Button,
  Checkbox,
  Dropdown,
  Flex,
  Image,
  List,
  Menu,
  MenuProps,
  Modal,
  Popconfirm,
  Popover,
  Space,
  Tag,
  Typography,
  message,
} from "antd";
import * as FileSaver from "file-saver";

import { find, forEach, isEmpty, isEqual, map } from "lodash";
import { parseAsArrayOf, parseAsString, useQueryState } from "next-usequerystate";
import Link from "next/link";
import { useRouter, useSearchParams } from "next/navigation";
import { useContext, useState } from "react";
import { FaExternalLinkAlt, FaPencilAlt, FaTrash } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import CardAndTitle from "../CardAndTitle";
import CustomModal from "../CustomModal";
import { Tags } from "../InfoColumn";
import PaginatedList from "../PaginatedList";
import ProjectAddModal from "./Add";
import RenderFilters from "./RenderFilters";
import UserCell from "./UserCell";

import { getSystemsExport } from "@/app/apiCalls/projects/exports";
import useFetch from "@/hooks/useFetch";
import { usePaginationParams } from "@/hooks/usePagination";
import { ISystem } from "@/services/server/data/ProjectData.service";
import { User } from "@/services/server/data/UserData.service";
import { ProjectStatus } from "@/types/Systems";
import DateDisplay from "../DateDisplay/DateDisplay";

const { Title, Text } = Typography;

export const getFullAddress = (project: ISystem) => {
  let fullAddress = "";

  if (project.address1) fullAddress += `${project.address1}, `;
  if (project.address2) fullAddress += `${project.address2}, `;

  if (project.city && project.state) {
    fullAddress += `${project.city}, ${project.state} `;
  } else {
    if (project.city) fullAddress += `${project.city} `;
    if (project.state) fullAddress += `${project.state} `;
  }
  if (project.postalCode) fullAddress += `${project.postalCode} `;

  return fullAddress;
};

type Props = {
  projects: ISystem[];
  customizations: Customizations[];
  projectsCount?: number;
  myProjectsCount?: number;
  company: any; // The company that owns the project
  superAdmin?: boolean;
  availableSubcontractors?: Promise<{ companies: Companies[] }>; // the list of Companues that are available to be assigned as subcontractors
  users: Promise<{
    users: Users[];
    count: number;
  }>; // the list of users that are available to be assigned as users (includes subcontractor company users)
};

const Projects = ({
  projects,
  customizations,
  company,
  superAdmin = false,
  users,
  availableSubcontractors,
  projectsCount,
  myProjectsCount,
}: Props) => {
  const { currentUser, currentCompany } = useContext(CurrentUserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updatingProjectsLoading, setUpdatingProjectsLoading] = useState(false);
  const searchParams = useSearchParams();
  const { getNumberOfPages, loading } = usePaginationParams(true);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const { loading: loadingExportSystems, callFetch: getSystemsExportFetch } = useFetch(getSystemsExport, {
    suppressError: true,
  });
  const router = useRouter();

  const [tab, setTab] = useQueryState("tab", parseAsString.withDefault(superAdmin ? "allProjects" : "myProjects"));
  const [statusFilter, setStatusFilter] = useQueryState(
    "status",
    parseAsArrayOf(parseAsString).withDefault(["active", "completed"]),
  );
  const [creatorsFilter, setCreatorsFilter] = useQueryState("creator", parseAsArrayOf(parseAsString).withDefault([]));
  const [ownersFilter, setOwnersFilter] = useQueryState("owner", parseAsArrayOf(parseAsString).withDefault([]));
  const [assigneesFilter, setAssigneesFilter] = useQueryState(
    "assignee",
    parseAsArrayOf(parseAsString).withDefault([]),
  );
  const [subcontractorFilter, setSubcontractorFilter] = useQueryState(
    "subcontractor",
    parseAsArrayOf(parseAsString).withDefault([]),
  );
  const [projectTypeFilter, setProjectTypeFilter] = useQueryState(
    "projectType",
    parseAsArrayOf(parseAsString).withDefault([]),
  );

  const { isSelected, setSelected, deSelectAll, selectAll, getSelectionState, getSelectedCount, getAllSelectedKeys } =
    useMultiSelect();

  const [showFilters, setShowFilters] = useState(false);
  const selectedCount = getSelectedCount(projects.length);

  const getProjectStatus = (project) => {
    return project.status || "active";
  };

  const getFullAddress = (project: ISystem) => {
    let fullAddress = "";

    if (project.address1) fullAddress += `${project.address1}, `;
    if (project.address2) fullAddress += `${project.address2}, `;

    if (project.city && project.state) {
      fullAddress += `${project.city}, ${project.state} `;
    } else {
      if (project.city) fullAddress += `${project.city} `;
      if (project.state) fullAddress += `${project.state} `;
    }
    if (project.postalCode) fullAddress += `${project.postalCode} `;

    return fullAddress;
  };

  const exportProjectsToCsv = async () => {
    const localSearchParams = new URLSearchParams(searchParams);

    localSearchParams.delete("limit");
    localSearchParams.delete("page");

    const csvContent = await getSystemsExportFetch(company?.id!, localSearchParams.toString());

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, `Condoit Projects - ${company?.name}.csv`);
  };

  const cardTitle = superAdmin ? null : (
    <div className="flex">
      <Button
        className="mr-2"
        size="large"
        type={tab === "myProjects" ? "primary" : "default"}
        shape="round"
        onClick={() => setTab("myProjects")}
      >
        My Projects {myProjectsCount ? `(${myProjectsCount})` : ""}
      </Button>
      <Button
        size="large"
        type={tab === "allProjects" ? "primary" : "default"}
        shape="round"
        onClick={() => setTab("allProjects")}
      >
        All Projects ({projectsCount})
      </Button>
    </div>
  );

  const isProjectSingleLine = (project: ISystem) => {
    const customizationArray = project.customizations?.split(",") ?? [];

    const listCustomizations = customizations.filter((item) => customizationArray.includes(item.id));

    const singleLineCustomization = listCustomizations.find((item) => item?.name?.includes("Single Line"));

    return singleLineCustomization ? true : false;
  };

  const isProjectSiteWalk = (project: ISystem) => {
    const customizationArray = project.customizations?.split(",") ?? [];

    const listCustomizations = customizations.filter((item) => customizationArray.includes(item.id));

    const projectCustomization = listCustomizations.find((item) => item?.name?.includes("Site Walk"));

    return projectCustomization ? projectCustomization : false;
  };

  const getTagsForProject = (project: ISystem) => {
    const tags: Tags[] = [];

    const customizationArray = project.customizations?.split(",") ?? [];

    const listCustomizations = customizations.filter((item) => customizationArray.includes(item.id));

    listCustomizations.map((item) => {
      tags.push({ color: "grey", value: item.name as string });
    });

    return tags;
  };

  async function deleteSelectedSystem(systemId: string) {
    try {
      setDeleteLoading(true);
      await deleteSystem(systemId);
      message.success("System deleted");
      router.refresh();
    } catch (error) {
      console.error(error);
      alert("Error deleting system");
    } finally {
      setDeleteLoading(false);
    }
  }

  async function updateSelectedSystemsStatus(statusIn: ProjectStatus) {
    try {
      setUpdatingProjectsLoading(true);

      const promises = [] as Promise<any>[];

      forEach(getSelectedSystemIds(), (id) => {
        const system = find(projects, { id }) as ISystem;
        promises.push(updateSystem({ ...system, status: statusIn as string }));
      });
      await Promise.all(promises);

      router.refresh();
      message.success("ISystem marked as " + statusIn);
      deSelectAll();
    } catch (error) {
      console.error(error);
      message.error("An error occurred:" + error.message);
    } finally {
      setUpdatingProjectsLoading(false);
    }
  }

  function getSelectedSystemIds() {
    const currentKeys = map(projects, "id");
    return getAllSelectedKeys(currentKeys);
  }

  function renderSelectedItemsList() {
    return (
      <List
        dataSource={getSelectedSystemIds()}
        renderItem={(item) => {
          const system = find(projects, { id: item }) as ISystem;

          return (
            <List.Item>
              <Typography.Text>{system.name}</Typography.Text>
            </List.Item>
          );
        }}
      />
    );
  }

  async function deleteSelectedSystems() {
    try {
      setDeleteLoading(true);

      const promises = [] as Promise<any>[];

      forEach(getSelectedSystemIds(), (id) => {
        promises.push(deleteSystem(id));
      });
      await Promise.all(promises);

      router.refresh();
      message.success("ISystem deleted successfully");
      deSelectAll();
    } catch (error) {
      console.error(error);
      message.error("An error occurred while deleting the system");
    } finally {
      setDeleteLoading(false);
      setDeleteConfirmOpen(false);
    }
  }

  const { allSelected } = getSelectionState();

  const columns = [
    {
      name: "selection",
      renderHeader: () => (
        <Checkbox
          checked={allSelected}
          onChange={() => (allSelected ? deSelectAll() : selectAll())}
          className="m-0 p-0"
        />
      ),
      renderBody: (system: ISystem) => (
        <Checkbox
          onChange={(event) => setSelected(system.id, event.target.checked)}
          checked={isSelected(system.id)}
          className="m-0 p-0"
        />
      ),
    },
    {
      name: "name",
      sortable: true,
      searchable: true,
      renderBody: (project: ISystem) => (
        <Link
          prefetch={false}
          href={superAdmin ? `/admin/company/${project.companyId}/projects/${project.id}` : `/projects/${project.id}`}
        >
          <div color="flex items-start">
            {project.name ? (
              <Title level={5} className="m-0 p-0" style={{ margin: 0 }}>
                {project.name}
              </Title>
            ) : (
              <Text type="secondary">No Name</Text>
            )}
            <Text type="secondary">{getFullAddress(project)}</Text>
            <div className="mt-2 flex">
              {getTagsForProject(project).map((tag, index) => (
                <Tag bordered={false} key={`${index}-${tag.value}`} color={tag.color}>
                  {tag.value}{" "}
                </Tag>
              ))}
            </div>
          </div>
        </Link>
      ),
    },
    {
      name: "id",
      searchable: true,
      renderBody: () => null,
      renderHeader: () => null,
    },
    {
      name: "assignedTo",
      searchable: false,
      renderBody: (system: ISystem) => (
        <UserCell emptyLabel="Unassigned" userId={system.assignedTo} user={system.assigned as User} />
      ),
      renderHeader: () => "Assigned To",
    },
    {
      name: "owner",
      searchable: false,
      renderBody: (system: ISystem) => (
        <UserCell emptyLabel="None" userId={system.owner} user={system.ownerUser as User} />
      ),
      renderHeader: () => "Owner",
    },
    {
      name: "creator",
      searchable: false,
      renderBody: (system: ISystem) => (
        <UserCell emptyLabel="Unknown" userId={system.createdBy} user={system.createdByUser as User} />
      ),
      renderHeader: () => "Creator",
    },
    {
      name: "status",
      searchable: false,
      sortable: true,
      renderBody: (system: ISystem) => {
        let status = "active";

        if (system.status == "archived") {
          status = "archived";
        } else if (system.status == "completed") {
          status = "completed";
        } else if (system.isActive == false) {
          status = "inactive";
        }

        switch (status) {
          case "archived":
            return <Tag color="red">Archived</Tag>;
          case "inactive":
            return <Tag color="red">Inactive</Tag>;
          case "completed":
            return <Tag color="green">Completed</Tag>;
          default:
            return <>Active</>;
        }
      },
      renderHeader: () => "Status",
    },
    {
      name: "updated_at",
      sortable: true,
      initialSort: true,
      renderBody: (project: ISystem) => <DateDisplay date={project.updated_at} />,
    },
    {
      name: "created_at",
      sortable: true,
      initialSort: true,
      renderBody: (project: ISystem) => <DateDisplay date={project.created_at} />,
    },
    {
      name: "actions",
      renderHeader: () => null,
      renderBody: (project: ISystem) => {
        const isSingleLine = isProjectSingleLine(project);

        return (
          <div className="flex justify-end">
            <Popover
              content={
                <Menu>
                  <Link
                    prefetch={false}
                    href={
                      superAdmin
                        ? `/admin/company/${project.companyId}/projects/${project.id}`
                        : `/projects/${project.id}`
                    }
                  >
                    <Menu.Item>
                      <Flex gap={2} align="center">
                        <FaPencilAlt />
                        <div className="ml-2">Edit Details</div>
                      </Flex>
                    </Menu.Item>
                  </Link>
                  {isSingleLine && (
                    <Menu.Item onClick={() => setIsOpen(true)}>
                      <Flex gap={2} align="center">
                        <FaExternalLinkAlt />
                        <div className="ml-2">View Single Line Diagram</div>
                      </Flex>
                    </Menu.Item>
                  )}
                  <Menu.Item>
                    {(currentUser?.isAdmin || superAdmin) && (
                      <Popconfirm
                        title="Delete the item?"
                        description="Are you sure to delete this item?"
                        okText="Delete"
                        cancelText="No"
                        onConfirm={() => deleteSelectedSystem(project.id)}
                      >
                        <Flex gap={2} align="center">
                          <FaTrash />
                          <div className="ml-2">Delete</div>
                        </Flex>
                      </Popconfirm>
                    )}
                  </Menu.Item>
                </Menu>
              }
            >
              <Button type="text" aria-label="Options" icon={<FiMoreVertical />} />
            </Popover>
          </div>
        );
      },
    },
  ];

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Delete",
      onClick: () => setDeleteConfirmOpen(true),
    },
    {
      key: "2",
      label: "Mark as Completed",
      onClick: () => updateSelectedSystemsStatus(ProjectStatus.Completed),
    },
    {
      key: "3",
      label: "Mark as Active",
      onClick: () => updateSelectedSystemsStatus(ProjectStatus.Active),
    },
    {
      key: "4",
      label: "Archive",
      onClick: () => updateSelectedSystemsStatus(ProjectStatus.Archived),
    },
  ];

  const numberOfProjectPages =
    tab === "myProjects" ? getNumberOfPages(myProjectsCount!) : getNumberOfPages(projectsCount!);
  const statusIsDefault =
    (statusFilter.includes("active") && statusFilter.includes("completed")) || isEqual(statusFilter, ["all"]);
  const filtersNotDefault =
    !isEmpty(ownersFilter) ||
    !isEmpty(assigneesFilter) ||
    !isEmpty(creatorsFilter) ||
    !isEmpty(subcontractorFilter) ||
    !isEmpty(projectTypeFilter) ||
    !statusIsDefault;

  return (
    <>
      <CardAndTitle
        bordered={false}
        titleComponent={cardTitle}
        search
        loading={loading || deleteLoading}
        buttons={
          <div className="flex flex-row gap-2">
            <Space>
              <Badge dot={filtersNotDefault} className="p-1">
                <Button
                  icon={<FilterFilled />}
                  onClick={() => {
                    setShowFilters(!showFilters);
                  }}
                >
                  Filter
                </Button>
              </Badge>

              <Button
                loading={loadingExportSystems}
                onClick={() => {
                  exportProjectsToCsv();
                }}
              >
                Export
              </Button>
              <Badge count={selectedCount}>
                <Dropdown
                  disabled={selectedCount === 0}
                  menu={{ items }}
                  placement="bottomLeft"
                  arrow={{ pointAtCenter: true }}
                >
                  <Button>Actions</Button>
                </Dropdown>
              </Badge>
              <ProjectAddModal
                company={company}
                superAdmin={superAdmin}
                customizations={customizations}
                projectsTotal={projectsCount!}
              />
            </Space>
          </div>
        }
      >
        <RenderFilters
          company={company}
          usersPromise={users}
          availableSubcontractorsPromise={availableSubcontractors!}
          showFilters={showFilters}
          setProjectTypeFilter={setProjectTypeFilter}
          projectTypeFilter={projectTypeFilter}
          setSubcontractorFilter={setSubcontractorFilter}
          subcontractorFilter={subcontractorFilter}
          setStatusFilter={setStatusFilter}
          statusFilter={statusFilter}
          ownersFilter={ownersFilter}
          setOwnersFilter={setOwnersFilter}
          assigneesFilter={assigneesFilter}
          setAssigneesFilter={setAssigneesFilter}
          creatorsFilter={creatorsFilter}
          setCreatorsFilter={setCreatorsFilter}
          customizations={customizations}
        />

        <div className="mt-3 overflow-auto">
          <PaginatedList
            hideSearch
            data={projects}
            columns={columns}
            numberOfPages={numberOfProjectPages}
            totalItems={tab === "myProjects" ? myProjectsCount : projectsCount}
            serverPaginated
            loading={loading}
          />
        </div>
        <CustomModal
          showTriggerButton={false}
          showCloseButton={false}
          showSubmitButton={false}
          title="Continue in the Condoit mobile app"
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          body="To view Single Lines, use our mobile app for IOS or Android. We'll be bringing this function to our web-based experience soon."
          customFooter={
            <Flex className="w-full" justify={"space-between"}>
              <Image
                alt="Apple App Store"
                onClick={() => {
                  window.open(
                    "https://apps.apple.com/us/app/con-doit/id1589162366",
                    "_blank", // <- This is what makes it open in a new window.
                  );
                }}
                width={"10em"}
                height={"3em"}
                src="https://condoit.io/wp-content/uploads/2023/03/app-store.png"
              />

              <Image
                alt="Android App Store"
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.condoit.mobileapp&hl=en_US&gl=US",
                    "_blank", // <- This is what makes it open in a new window.
                  );
                }}
                width={"10em"}
                height={"3em"}
                src="https://condoit.io/wp-content/uploads/2023/03/play-store.png"
              />
            </Flex>
          }
        />
      </CardAndTitle>

      <Modal
        title="Confirm Delete"
        onOk={deleteSelectedSystems}
        open={deleteConfirmOpen}
        onCancel={() => setDeleteConfirmOpen(false)}
        confirmLoading={deleteLoading}
      >
        <>
          Are you sure you want to delete these systems?
          {renderSelectedItemsList()}
        </>
      </Modal>
    </>
  );
};

export default Projects;

"use client";
import { Flex, Heading, Icon, Link, List, ListIcon, ListItem, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { HiCheck } from "@react-icons/all-files/hi/HiCheck";
import { HiCheckCircle } from "@react-icons/all-files/hi/HiCheckCircle";
import { ElementType, ReactElement } from "react";
import { Card, CardProps } from "./Card";
import "./pricingCard.css";
export interface PricingCardData {
  features?: string[];
  featuresHtml?: string;
  name: string;
  price?: string;
}

interface PricingCardProps extends CardProps {
  data: PricingCardData;
  showPrice?: boolean | null;
  icon?: ElementType;
  button: ReactElement;
  oneTimePayment?: boolean;
  frequencyText?: string;
  darkMode?: boolean;
}

export const PricingCard = (props: PricingCardProps) => {
  const { data, darkMode, icon, showPrice, button, oneTimePayment = false, frequencyText, ...rest } = props;
  const { features, price, name } = data;
  // const accentColor = useColorModeValue("Black", "white");

  return (
    <Card
      style={{ minWidth: "400px" }}
      rounded={{ sm: "xl" }}
      {...rest}
      className="h-full flex justify-between flex-col"
    >
      <div>
        <VStack spacing={6}>
          {icon && <Icon aria-hidden as={icon} fontSize="4xl" />}
          <Heading size="lg" fontWeight="extrabold">
            {name}
          </Heading>
        </VStack>

        <Flex
          direction="column"
          align="center"
          justify="center"
          fontWeight="extrabold"
          // color={accentColor}
          my="8"
        >
          {price && showPrice ? (
            <>
              <Heading size="2xl" color={"brand.500"} fontWeight="inherit" lineHeight="0.9em">
                {price}
              </Heading>
              {!oneTimePayment && (
                <Text fontWeight="bold" fontSize="md">
                  {frequencyText ? frequencyText : "per month"}
                </Text>
              )}
            </>
          ) : (
            <Link
              href="https://condoit.io/contact-us/"
              target="_blank"
              color={"brand.500"}
              fontWeight="bold"
              fontSize={"x-large"}
              _hover={{ color: "brand.300" }}
            >
              Contact us
            </Link>
          )}
        </Flex>
      </div>
      {features && features.length > 0 ? (
        <List spacing="4" mb="8" maxW="28ch" mx="auto">
          {features?.map((feature, index) => (
            <ListItem fontWeight="medium" key={index}>
              <ListIcon
                fontSize="xl"
                as={HiCheck}
                marginEnd={2}
                // color={accentColor}
              />
              {feature}
            </ListItem>
          ))}
        </List>
      ) : (
        <List spacing="4" mb="8" maxW="28ch" mx="auto">
          <div className={darkMode ? "pricingCard-dark" : "pricingCard"}>
            <div
              className={darkMode ? "pricingCard-dark" : "pricingCard"}
              dangerouslySetInnerHTML={{ __html: data.featuresHtml! }}
            ></div>
          </div>
        </List>
      )}
      {button}
    </Card>
  );
};

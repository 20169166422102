import { getUserById } from "@/app/apiCalls/user/usersCRUD";
import useFetch from "@/hooks/useFetch";
import { User } from "@/services/server/data/UserData.service";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";

const UserCell = ({
  userId,
  emptyLabel,
  user,
}: {
  userId: string | null | undefined;
  emptyLabel: string;
  user?: User;
}) => {
  const [_user, setUser] = useState<User | null | undefined>(user);

  const {
    loading,
    error,
    callFetch: getUserByIdFetch,
  } = useFetch(getUserById, {
    fetchOnLoad: false,
    args: [userId],
    suppressError: true,
  });

  useEffect(() => {
    if (!loading && isNil(user) && userId && userId?.length > 0) {
      getUserByIdAPI();
    }
  }, [userId]);

  async function getUserByIdAPI() {
    setUser(null);
    const user = await getUserByIdFetch(userId);
    setUser(user);
  }

  function renderUser(user) {
    if (isNil(user)) {
      if (userId && userId?.length > 0) {
        return <div>Invalid User</div>;
      }
      return <div>{emptyLabel}</div>;
    }
    return (
      <div>
        <p>
          {user.firstName} {user.lastName}
        </p>
      </div>
    );
  }

  return !loading ? renderUser(_user) : <div>Loading...</div>;
};

export default UserCell;

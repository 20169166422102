"use client";
import { CurrentUserContext } from "@/app/contexts/currentUserContext";
import {
  Box,
  Button,
  Card,
  ChakraProvider,
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  ToastId,
  Tooltip,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { HiCheck } from "@react-icons/all-files/hi/HiCheck";
import { useRouter } from "next/navigation";
import { useContext, useEffect, useRef, useState } from "react";
import { ActionButton } from "./ActionButton";
import { PricingCard } from "./PricingCard";

import { createCookie } from "@/app/actions";
import { Plan, SubscriptionTermLength, SubscriptionType } from "@/models";

import { PlansWithStripeData } from "@/app/get-started/choose-plan/page";
import API from "@/services/API.service";
import { Primary_Accent_color, Primary_Theme_color } from "@/theme/antTheme";
import chakraTheme from "@/theme/chakraTheme";
import { find, isNil } from "lodash";
import { useSearchParams } from "next/navigation";
import Stripe from "stripe";

interface Props {
  plans: PlansWithStripeData[];
  onlyCards?: boolean;
  onSelection?: (plan: Plan) => void;
}

const Pricing = ({ plans, onlyCards = false, onSelection }: Props) => {
  const [isYearly, setIsYearly] = useState(false);
  const [loading, setloading] = useState(false);
  const searchParams = useSearchParams();

  const {
    currentCompany: company,
    activeSubscription,
    currentUser: user,
    companySubscriptions,
    refreshUserContext,
  } = useContext(CurrentUserContext);

  const toast = useToast();
  const toastIdRef = useRef<ToastId | null>(null);

  useEffect(() => {
    const deactivated = searchParams?.get("deactivated");
    if (deactivated) {
      toast({
        title: "Your account has been deactivated",
        description: "Please contact Condoit Support to reactivate your account or upgrade your plan",
        status: "error",
        duration: 9000,
      });
    }
  }, []);

  const router = useRouter();
  // const path = usePathname();

  const [sliderValue, setSliderValue] = useState(1);
  const [showTooltip, setShowTooltip] = useState(true);

  const accentColor = "white";

  const trialSubscription = companySubscriptions?.find((sub) => sub.type === SubscriptionType.Trial);
  const hideTrial = true; // !isNil(trialSubscription);

  async function handleSubscriptionSelection(stripePrice: Stripe.Price, quantity: number) {
    setloading(true);
    try {
      await createCookie(
        "checkout",
        JSON.stringify({
          priceId: stripePrice.id,
          subscription: isYearly ? SubscriptionTermLength.Yearly : SubscriptionTermLength.Monthly,
          quantity: quantity,
          promo: "",
        }),
      );
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }

    router.push("/get-started/checkout");

    // processingPlan(
    //   toast,
    //   SubscriptionType.Individual,
    //   isYearly,
    //   company!.id,
    //   companySubscriptions,
    //   router
    // )
  }

  async function activateTrial() {
    setloading(true);

    try {
      toastIdRef.current = toast({
        title: "One moment...",
        description: `Welcome to Condoit! Your Free Trial is being processed`,
        status: "info",
        duration: 9000,
        isClosable: true,
      });

      const response = await API.post(`/api/company/${company!.id}/subscriptions`, {
        body: JSON.stringify({
          seatCount: 0,
        }),
      });

      if (response.success !== true) {
        console.log("ERROR: ===", response.error);
      } else {
        await refreshUserContext();
        try {
          router.push("/projects/?stripe=success&type=Free%20trial");
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  }

  return (
    <ChakraProvider theme={chakraTheme} cssVarsRoot="html">
      <Box py={5} px={{ base: 0, lg: 5 }}>
        {!hideTrial && (
          <Card
            borderRadius={"12px"}
            width={"100%"}
            height={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
            p={8}
            color={"white"}
            bg={"#254D5B"}
            mb={10}
          >
            <Stack width={"100%"} justifyContent={"center"} alignContent={"center"} textAlign={"center"}>
              <Box flex={1}>
                <Heading size="lg" fontWeight="extrabold">
                  14 Day Free Trial
                </Heading>
              </Box>
            </Stack>
            <Stack flex={1} h={"100%"} display={"flex"} direction={{ md: "row", base: "column-reverse" }}>
              <Flex align="flex-start" justify="left" fontWeight="bold" alignItems={"center"}>
                <VStack justifyContent={"center"} width={"100%"}>
                  <List orientation="vertical" spacing="4" pl={5} pr={10} fontSize={"xl"}>
                    <ListItem fontWeight="medium" display={"flex"} alignItems={"center"}>
                      <ListIcon
                        fontSize="xl"
                        as={HiCheck}
                        marginEnd={2}
                        color={accentColor}
                        bg={"brand.500"}
                        h={8}
                        w={8}
                        borderRadius={"50%"}
                      />
                      Unlimited Access to All Condoit Features
                    </ListItem>

                    <ListItem fontWeight="medium" display={"flex"} alignItems={"center"}>
                      <ListIcon
                        fontSize="xl"
                        as={HiCheck}
                        marginEnd={2}
                        color={accentColor}
                        bg={"brand.500"}
                        h={8}
                        w={8}
                        borderRadius={"50%"}
                      />
                      Share projects with internal and external stakeholders
                    </ListItem>
                    <ListItem fontWeight="medium" display={"flex"} alignItems={"center"}>
                      <ListIcon
                        fontSize="xl"
                        as={HiCheck}
                        marginEnd={2}
                        color={accentColor}
                        bg={"brand.500"}
                        h={8}
                        w={8}
                        borderRadius={"50%"}
                      />
                      Create site walks, Digital Single-line diagrams, Bill-of-Materials, and other Condoit Reports
                    </ListItem>
                  </List>
                </VStack>
              </Flex>
              <Flex>
                <Box
                  justifyContent={"center"}
                  flex={1}
                  height={"!100%"}
                  alignItems={"center"}
                  display={"flex"}
                  borderRadius={"50%"}
                  p={5}
                >
                  <Box>
                    {/* {SIDE PANEL} */}
                    <img style={{ maxHeight: "300px" }} alt="free trial image" src="/images/four-devices.png" />
                  </Box>
                </Box>
              </Flex>
            </Stack>
            <Box>
              <ActionButton loading={loading} onClick={() => activateTrial()}>
                Get Started
              </ActionButton>
            </Box>
          </Card>
        )}

        <Box mb={10}>
          {!hideTrial && <h3 className="text-center text-xl italic text-gray-900">or</h3>}
          <h2 className="mt-3 text-center text-3xl font-bold tracking-tight text-gray-900">Choose Your Plan</h2>
        </Box>

        {!onlyCards && (
          <Box mb={24} px={3} bg="white" position={"sticky"} top={-1} zIndex={15}>
            <Flex width={"100%"} justifyContent={"center"} m={5} position={"sticky"}>
              <Box bg={"gray.200"} h="auto" p={1.5} borderRadius={9}>
                <Button variant={!isYearly ? "white" : "transparent"} onClick={() => setIsYearly(false)}>
                  Monthly
                </Button>
                <Button variant={isYearly ? "white" : "transparent"} onClick={() => setIsYearly(true)}>
                  Yearly
                </Button>
              </Box>
            </Flex>
            <Text fontSize={"xl"} fontWeight={"bold"}>
              How many users do you have?
            </Text>
            <Box mt="5" mb="10" display="flex">
              <Box flex={10}>
                <Slider
                  id="slider"
                  defaultValue={1}
                  value={sliderValue}
                  color={"brand"}
                  min={1}
                  max={50}
                  onChange={(v: number) => setSliderValue(v)}
                  onMouseEnter={() => setShowTooltip(true)}
                  // onMouseLeave={() => setShowTooltip(false)}
                  size={"lg"}
                >
                  <SliderMark
                    value={1}
                    mt="4"
                    ml="-4"
                    px={3.5}
                    py={1}
                    borderRadius={30}
                    fontSize="md"
                    color="white"
                    bg={Primary_Accent_color}
                    fontWeight="extrabold"
                  >
                    1
                  </SliderMark>
                  <SliderMark
                    value={10}
                    mt="4"
                    ml="-4"
                    px={3}
                    py={1}
                    borderRadius={30}
                    fontSize="md"
                    color="white"
                    bg={Primary_Accent_color}
                    fontWeight="extrabold"
                  >
                    10
                  </SliderMark>
                  <SliderMark
                    value={25}
                    mt="4"
                    ml="-4"
                    px={3}
                    py={1}
                    borderRadius={30}
                    fontSize="md"
                    color="white"
                    bg={Primary_Accent_color}
                    fontWeight="extrabold"
                  >
                    25
                  </SliderMark>
                  <SliderMark
                    value={50}
                    mt="4"
                    ml="-4"
                    px={3}
                    py={1}
                    borderRadius={30}
                    fontSize="md"
                    color="white"
                    bg={Primary_Accent_color}
                    fontWeight="extrabold"
                  >
                    50
                  </SliderMark>
                  <SliderTrack>
                    <SliderFilledTrack bg={Primary_Theme_color} color={"white"} />
                  </SliderTrack>
                  <Tooltip
                    zIndex={99999}
                    hasArrow
                    bg={Primary_Theme_color}
                    placement="top"
                    label={sliderValue === 1 ? "1 user" : `${sliderValue} users`}
                  >
                    <SliderThumb height={6} width={6} bg={Primary_Theme_color} color="white">
                      {sliderValue}
                    </SliderThumb>
                  </Tooltip>
                </Slider>
              </Box>
            </Box>
          </Box>
        )}

        <SimpleGrid
          columns={{ base: 1, lg: 3 }}
          spacing={{ base: "8", lg: "0" }}
          maxW="7xl"
          mx="auto"
          justifyItems="center"
          alignItems="flex-start"
        >
          {plans.map((plan, index) => {
            const stripePrice: Stripe.Price = isYearly
              ? (find(plan.stripeData, (price) => price.lookup_key?.includes("year")) as Stripe.Price)
              : (find(plan.stripeData, (price) => price.lookup_key?.includes("month")) as Stripe.Price);
            const price = stripePrice?.unit_amount! / 100;
            const isCurrentPlan = activeSubscription?.planId === plan.id;
            return (
              <PricingCard
                darkMode={index === 1}
                key={plan.id!}
                zIndex={index === 1 ? 1 : undefined}
                showPrice={plan.allowPurchase}
                transform={index === 1 ? { lg: "scale(1.05)" } : undefined}
                bg={index === 1 ? Primary_Theme_color : undefined}
                color={index === 1 ? "white" : undefined}
                data={{
                  price: `$${price}`,
                  name: plan.name!,
                  featuresHtml: plan.longDescription!,
                }}
                frequencyText={isYearly ? "per year" : "per month"}
                // icon={SiMicrosoft}
                button={
                  <>
                    {sliderValue > 1 && plan.allowPurchase && (
                      <Text fontSize="sm" p="2.5" align="center">
                        {`$${price * sliderValue} / ${isYearly ? "year" : "month"} for ${sliderValue} user${
                          sliderValue > 1 ? "s" : ""
                        }`}
                      </Text>
                    )}
                    {plan.allowPurchase ? (
                      <ActionButton
                        // variant="outline"
                        // borderWidth="2px"
                        id={"planbutton" + index}
                        disabled={isCurrentPlan}
                        loading={loading}
                        onClick={() =>
                          onSelection ? onSelection(plan) : handleSubscriptionSelection(stripePrice, sliderValue)
                        }
                      >
                        {isCurrentPlan ? "Your Current Plan" : `Choose ${plan.name} Plan`}
                      </ActionButton>
                    ) : (
                      <ActionButton
                        style={{ height: "60px", display: "flex", alignItems: "center", justifyContent: "center" }}
                        target="_blank"
                        href="https://condoit.io/contact-us/"
                      >
                        Contact Us
                      </ActionButton>
                    )}
                  </>
                }
              />
            );
          })}
        </SimpleGrid>
      </Box>
    </ChakraProvider>
  );
};

export default Pricing;

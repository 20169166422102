"use client";
import { RadioCard } from "@/components/RadioCard";

import { useYupValidationResolver } from "@/utils";
import { projectTypeOptionsTemplate } from "@/utils/projectTypeTemplate";
import { PlusOutlined } from "@ant-design/icons";
import {
  Box,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
  useRadioGroup,
} from "@chakra-ui/react";
import { useRouter } from "next/navigation";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { createSystem } from "@/app/apiCalls/projects/projectsCRUD";
import { CurrentUserContext } from "@/app/contexts/currentUserContext";
import AddressAutocompleteInput from "@/components/AddressAutocompleteInput";
import useGetCompanies from "@/hooks/useGetCompanies";
import useGetCompaniesUsersList from "@/hooks/useGetCompaniesUsersList";

import usePermission from "@/hooks/usePermission";
import { PlanPermission } from "@/services/permissions/Permissions.service";
import { CompanySubscriptionWithPlan } from "@/services/server/utility/Subscription.service";
import { STATES } from "@/utils/usStates";
import { Companies, Customizations, Systems } from "@prisma/client";
import { Alert, Button, Modal, message, notification } from "antd";
import { find, isNil } from "lodash";
import * as yup from "yup";
import ProjectLimit from "./ProjectLimit";

type Props = {
  company: Companies;
  action?: () => void;
  superAdmin?: boolean;
  customizations: Customizations[];
  projectsTotal: number;
};

const ProjectAddModal = ({ action, company, superAdmin = false, customizations, projectsTotal }: Props) => {
  const {
    currentUser,
    hasFlexSubscription,
    hasActiveSubscription,
    currentCompany,
    activeSubscription: currentSubscription,
  } = useContext(CurrentUserContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { projectPermissions } = usePermission(
    currentUser!,
    ((currentSubscription as CompanySubscriptionWithPlan)?.plan?.planPermissions as PlanPermission[]) || [],
  );
  const [submitting, setSubmitting] = useState(false);
  const [projectTypes, setProjectTypes] = useState<any>([]);
  const [projectType, setProjectType] = useState("Single Line Diagram");

  const { users, fetchUsers: fetchUsersForCompany } = useGetCompaniesUsersList();
  const { companies: subcontractors, fetchCompanies: fetchSubcontractors } = useGetCompanies();
  const subcontractorIds = company?.subcontractors ? JSON.parse(company.subcontractors) : [];

  useEffect(() => {
    if (subcontractorIds?.length > 0) {
      fetchSubcontractors(subcontractorIds);
    }
  }, [company]);

  type ProjectForm = {
    status: string;
    subcontractor: string;
    owner: string;
    assignedTo: string;
    name: string;
    createdBy: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  };

  const validationSchema = yup.object().shape({
    status: yup.string().nullable(),
    subcontractor: yup.string().nullable(),
    owner: yup.string().nullable(),
    assignedTo: yup.string().nullable(),
    name: yup.string().trim().required("Project Name is required"),
    createdBy: superAdmin ? yup.string().required("User is required") : yup.string().nullable(),
    address1: yup.string().nullable(),
    address2: yup.string().nullable(),
    city: yup.string().nullable(),
    state: yup.string().nullable(),
    zip: yup.string().nullable(),
  });

  const initialFormValues = {
    status: "active",
    subcontractor: "",
    owner: "",
    assignedTo: "",
    address1: "",
    address2: "",
    city: "",
    name: "",
    state: "",
    zip: "",
    user: superAdmin ? "" : currentUser?.id,
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
    watch,
    setValue,
  } = useForm<ProjectForm>({
    resolver: useYupValidationResolver(validationSchema),
    defaultValues: initialFormValues,
  });

  const selectedSubcontractor = watch("subcontractor");

  useEffect(() => {
    if (company?.id) {
      fetchUsersForCompany(company.id, [selectedSubcontractor]);
    }
  }, [selectedSubcontractor, company]);

  //todo: type of project stuff

  const router = useRouter();

  const onCloseModal = () => {
    reset(initialFormValues);
    onClose();
  };

  async function onSubmit(values: ProjectForm) {
    try {
      setSubmitting(true);
      const response = await createSystem({
        status: values.status,
        subcontractor: values.subcontractor === "" ? null : values.subcontractor,
        owner: values.owner === "" ? null : values.owner,
        assignedTo: values.assignedTo === "" ? null : values.assignedTo,
        companyId: company!.id,
        name: values.name.trim(),
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        postalCode: values.zip,
        createdBy: values.createdBy,
        customizations: findCustomizationIdByName(projectType),
      } as Systems);

      message.success("Project Created");

      if (superAdmin) {
        onCloseModal();
        router.push(`/admin/company/${company.id}/projects/${response?.id}`);
      } else {
        onCloseModal();
        router.push(`/projects/${response?.id}`);
        reset(initialFormValues);
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message,
        placement: "bottom",
      });
    } finally {
      setSubmitting(false);
    }
  }

  function findCustomizationIdByName(name: string) {
    let result = "";
    for (let obj of customizations) {
      if (obj.name === name) {
        result += String(obj.id) + ",";
      }
    }
    if (result.length > 0) {
      result = result.slice(0, -1); // Remove the trailing comma
    }
    return result;
  }

  function findCustomizationByName(name: string) {
    for (let obj of customizations) {
      if (obj.name === name) {
        return obj;
      }
    }
  }

  const updateProjectTypes = () => {
    const newProjectTypes = [...projectTypeOptionsTemplate];
    newProjectTypes.forEach((group) => {
      group.items.forEach((item: any) => {
        // eslint-disable-next-line no-param-reassign

        item.value =
          find(customizations, (cust: any) => {
            return cust.name === item.customizationName;
          })?.id || null;
      });
    });
    setProjectTypes(newProjectTypes);
  };

  useEffect(() => {
    if (!isNil(customizations)) {
      updateProjectTypes();
    }
  }, [customizations]);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "projectTypes",
    defaultValue: projectType,
    onChange: setProjectType,
  });

  const group = getRootProps();

  const filterProjectTypes = (projectTypes: any[], companyId: string) => {
    return projectTypes.map((projectType) => {
      return {
        ...projectType,
        items: checkItems(projectType, companyId),
      };
    });
  };

  const checkItems = (projectType: any, companyId: string) => {
    return projectType.items.filter((item: any) => {
      const customization = findCustomizationByName(item.customizationName);

      if (customization?.equipmentQuestions) {
        const obj = JSON.parse(customization.equipmentQuestions);

        if (obj.excludeCompanies) {
          return !obj.excludeCompanies.includes(companyId);
        }
        if (obj.includeCompanies) {
          return obj.includeCompanies.includes(companyId);
        }
      } else {
        return false;
      }

      return true;
    });
  };

  const filteredProjectTypes = filterProjectTypes(projectTypes, company!.id);

  return (
    <Box zIndex={999}>
      <Stack direction="row">
        <Button
          id="add_project_button"
          icon={<PlusOutlined />}
          onClick={onOpen}
          type="primary"
          disabled={!hasActiveSubscription}
        >
          Add Project
        </Button>
      </Stack>

      <Modal
        onCancel={onClose}
        maskClosable={false}
        title="Create New Project"
        footer={
          projectPermissions.canCreate(projectsTotal)
            ? [
                <Button key="back" className="mr-3" onClick={onClose}>
                  Close
                </Button>,
                <Button
                  form="addProjectForm"
                  key="submit"
                  id="save-project-button"
                  type="primary"
                  htmlType="submit"
                  loading={submitting}
                  disabled={submitting}
                >
                  Save Project
                </Button>,
              ]
            : [
                <Button key="back" className="mr-3" onClick={onClose}>
                  Close
                </Button>,
              ]
        }
        open={isOpen}
        width={"fit-content"}
      >
        {!projectPermissions.canCreate(projectsTotal) ? (
          <ProjectLimit limit={projectPermissions.projectLimit} />
        ) : (
          <form id="addProjectForm" onSubmit={handleSubmit(onSubmit)}>
            {hasFlexSubscription ? (
              <>
                <Stack mb="4" spacing="3">
                  <Text>Your subsription only supports projects assigned to you as a subcontractor.</Text>
                  <Alert
                    type="warning"
                    message="To create a project assigned to a different subcontractor,
                  please upgrade your subscription."
                  ></Alert>
                  <Button type="primary" href="/get-started/choose-plan">
                    Upgrade Subscription
                  </Button>
                </Stack>
              </>
            ) : (
              <>
                {projectsTotal === projectPermissions.projectLimit - 1 && (
                  <Alert
                    type="warning"
                    message="You are about to reach the limit of projects you can create"
                    description="Please upgrade your subscription to create more projects"
                  ></Alert>
                )}

                <Stack>
                  <Box>
                    <Stack spacing="5">
                      <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                        <FormControl id="ProjectForm" isInvalid={!!errors.name}>
                          <FormLabel>Project Name *</FormLabel>
                          <Input {...register("name")} />
                          <FormErrorMessage>{errors.name && errors.name?.message}</FormErrorMessage>
                        </FormControl>
                      </Stack>
                      <FormLabel> Project Details</FormLabel>
                      <Stack spacing="5">
                        <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                          <FormControl>
                            <FormLabel>{"Status"}</FormLabel>
                            <Select {...register("status")}>
                              <>
                                <option disabled value="">
                                  Select status
                                </option>
                                {[
                                  { value: "active", name: "Active" },
                                  { value: "completed", name: "Completed" },
                                  { value: "archived", name: "Archived" },
                                ].map((item: any) => {
                                  return (
                                    <option key={item.value} value={item.value}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </>
                            </Select>
                          </FormControl>
                          <FormControl isDisabled={subcontractorIds.length == 0}>
                            <FormLabel>{"Subcontractor"}</FormLabel>
                            <Select {...register("subcontractor")}>
                              <>
                                <option value="">Select subcontractor</option>
                                {subcontractors
                                  .map((subcontractor) => {
                                    return {
                                      name: subcontractor.name ?? "Untitled",
                                      value: subcontractor.id,
                                    };
                                  })
                                  .map((item: any) => {
                                    return (
                                      <option key={item.value} value={item.value}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                              </>
                            </Select>
                          </FormControl>
                        </Stack>
                        <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                          <FormControl>
                            <FormLabel>{"Owner"}</FormLabel>
                            <Select {...register("owner")}>
                              <>
                                <option value="">Select owner</option>
                                {users
                                  .map((user) => {
                                    return {
                                      name: user.email,
                                      value: user.id,
                                    };
                                  })
                                  .map((item: any) => {
                                    return (
                                      <option key={item.value} value={item.value}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                              </>
                            </Select>
                          </FormControl>
                          <FormControl>
                            <FormLabel>{"Assigned To"}</FormLabel>
                            <Select {...register("assignedTo")}>
                              <>
                                <option value="">Select assignee</option>
                                {users
                                  .map((user) => {
                                    return {
                                      name: user.email,
                                      value: user.id,
                                    };
                                  })
                                  .map((item: any) => {
                                    return (
                                      <option key={item.value} value={item.value}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                              </>
                            </Select>
                          </FormControl>
                        </Stack>
                        {superAdmin ? (
                          <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                            <FormControl isInvalid={!!errors.createdBy}>
                              <FormLabel>Created by user *</FormLabel>
                              <Select {...register("createdBy")}>
                                <>
                                  <option value="">Select user</option>
                                  {users
                                    .map((user) => {
                                      return {
                                        name: `${user.firstName} ${user.lastName} (${user.email})`,
                                        value: user.id,
                                      };
                                    })
                                    .map((item: any) => {
                                      return (
                                        <option key={item.value} value={item.value}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </>
                              </Select>
                              <FormErrorMessage>{errors.createdBy && errors.createdBy?.message}</FormErrorMessage>
                              <FormHelperText>
                                When creating a project as a Condoit admin, you must select a user for this project to
                                belong to.
                              </FormHelperText>
                            </FormControl>
                          </Stack>
                        ) : null}
                      </Stack>
                      <FormLabel>Site Location</FormLabel>
                      <FormControl id="address1">
                        <FormLabel>Address Line 1</FormLabel>
                        <AddressAutocompleteInput
                          onSelectPlace={(addressComponents) => {
                            setValue("address1", addressComponents.address1);
                            setValue("city", addressComponents.city);
                            setValue("state", addressComponents.state);
                            setValue("zip", addressComponents.postalCode);
                          }}
                        />
                      </FormControl>
                      <FormControl id="address1">
                        <FormLabel>Address Line 2</FormLabel>
                        <Input {...register("address2")} />
                      </FormControl>
                      <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                        <FormControl id="city">
                          <FormLabel>City</FormLabel>
                          <Input {...register("city")} />
                        </FormControl>
                        <FormControl id="state">
                          <FormLabel>State</FormLabel>
                          <Select placeholder="Select option" {...register("state")}>
                            {Object.entries(STATES).map(([abbrev, state]) => (
                              <option key={abbrev} value={abbrev}>
                                {state}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                      <FormControl id="zip">
                        <FormLabel>Postal Code</FormLabel>
                        <Input placeholder="10961" {...register("zip")} />
                      </FormControl>
                    </Stack>
                  </Box>
                </Stack>

                <Divider my={10} />
                {projectTypes.length > 0 && (
                  <>
                    <FormLabel>Project Types</FormLabel>
                    <Box bg="bg-surface" boxShadow="sm" borderRadius="lg">
                      <Stack spacing="5" divider={<StackDivider />} {...group}>
                        {filteredProjectTypes.map((projectType: any, index: number) => {
                          return (
                            <Stack
                              key={`types_${index} `}
                              justify="space-between"
                              direction="row"
                              spacing="4"
                              width={"100%"}
                            >
                              <Stack spacing="0.5" fontSize="sm" width={"full"}>
                                <Text color="emphasized" fontWeight="bold">
                                  {projectType.name}
                                </Text>
                                {projectType.items.map((item: any, index: number) => {
                                  const name = item.customizationName;
                                  const radio = getRadioProps({
                                    value: name,
                                  });

                                  return (
                                    <RadioCard key={index} {...radio}>
                                      <Box my={5} key={index + "_item"}>
                                        <Text color="muted">{item.name}</Text>
                                        <Text as="sub">{item.helpText}</Text>
                                      </Box>
                                    </RadioCard>
                                  );
                                })}
                              </Stack>

                              <Stack direction={{ base: "column", sm: "row" }} spacing={{ base: "0", sm: "1" }}></Stack>
                            </Stack>
                          );
                        })}
                      </Stack>
                    </Box>
                  </>
                )}
              </>
            )}
          </form>
        )}
      </Modal>
    </Box>
  );
};

export default ProjectAddModal;

export const projectTypeOptionsTemplate = [
  {
    name: "EV Charger Installation",
    items: [
      {
        name: "Miller Site Walk",
        customizationName: "Miller Site Walk",
      },
      {
        name: "Site Walk",
        customizationName: "EV Site Walk",
      },
      {
        name: "Rip and Replace Site Walk",
        customizationName: "Rip and Replace Site Walk",
      },
      {
        name: "Made Ready Site Walk",
        customizationName: "Made Ready Site Walk",
      },
      {
        name: "New Installation Site Walk",
        customizationName: "New Installation Site Walk",
      },
    ],
  },
  {
    name: "Solar Installation",
    items: [{ name: "Solar Site Walk", customizationName: "Solar Site Walk" }],
  },
  {
    name: "Lighting Assessment",
    items: [
      {
        name: "Lighting Site Walk",
        customizationName: "Lighting Site Walk",
      },
    ],
  },
  {
    name: "Other",
    items: [
      { name: "General Site Walk", customizationName: "General Site Walk" },
      { name: "Single Line Diagram", customizationName: "Single Line Diagram" },
    ],
  },
];

"use client";

import { Button, ButtonProps } from "antd";
import { useState } from "react";

export const ActionButton = (props: ButtonProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      type="primary"
      size="large"
      style={{ ...props.style, height: "60px" }}
      onClick={(e) => {
        setLoading(true);
        if (props.onClick) {
          props.onClick(e);
        }
      }}
      {...props}
    >
      <b>{props.children}</b>
    </Button>
  );
};

import API from "@/services/API.service";
import { IDataResult } from "@/types/apiTypes";
import { CompanySubscriptions } from "@prisma/client";

export async function activateSubscription(companyId: string, subscriptionId: string) {
  const response = await API.post(`/api/company/${companyId}/subscriptions/${subscriptionId}/activate`);

  if (response.success !== true) throw new Error(response.message);

  return response;
}

export async function createSubscriptionAPI(companyId: string, body: any, stripeSubscriptionId?: string) {
  const searchParams = new URLSearchParams();
  if (stripeSubscriptionId) searchParams.append("stripeSubscriptionId", stripeSubscriptionId);

  const response = await API.post(`/api/company/${companyId}/subscriptions?${searchParams.toString()}`, {
    body: JSON.stringify(body),
  });

  if (response.success !== true) throw new Error(response.message);

  return response;
}

export async function deleteSubscriptionAPI(companyId: string, subscriptionId: string) {
  const response = await API.delete(`/api/company/${companyId}/subscriptions/${subscriptionId}`);

  if (response.success !== true) throw new Error(response.message);

  return true;
}

export async function updateSubscriptionAPI(
  companyId: string,
  subscription: Partial<CompanySubscriptions>,
): Promise<IDataResult<CompanySubscriptions>> {
  const response = await API.put(`/api/company/${companyId}/subscriptions/${subscription.id}`, {
    body: JSON.stringify(subscription),
  });

  if (response.success !== true) throw new Error(response.message);

  return response;
}

export async function updateStripeSubscriptionAPI(companyId, quantity, subscriptionId, selectedPlanId, termLength) {
  const response = await API.put(`/api/company/${companyId}/subscriptions/stripe`, {
    body: JSON.stringify({
      subscriptionId: subscriptionId,
      quantity: quantity,
      planId: selectedPlanId,
      termLength: termLength,
    }),
  });

  if (response.success !== true) throw new Error(response.message);

  return response;
}

export async function upgradeSubscriptionAPI(companyId, planId, quantity, subscriptionId) {
  const response = await API.post(`/api/company/${companyId}/subscriptions/${subscriptionId}/upgrade`, {
    body: JSON.stringify({
      planId: planId,
      quantity: quantity,
    }),
  });

  if (response.success !== true) throw new Error(response.message);

  return response;
}

export async function reactivateSubscription(companyId, subscriptionId) {
  const response = await API.post(`/api/company/${companyId}/subscriptions/${subscriptionId}/reactivate`);

  if (response.success !== true) throw new Error(response.message);

  return response;
}

export async function downGradeSubscriptionAPI(
  companyId,
  quantity,
  subscriptionId,
  selectedPlanId,
  termLength,
  reason,
) {
  const response = await API.post(`/api/company/${companyId}/subscriptions/${subscriptionId}/downgrade`, {
    body: JSON.stringify({
      subscriptionId: subscriptionId,
      quantity: quantity,
      planId: selectedPlanId,
      termLength: termLength,
      reason: reason,
    }),
  });

  if (response.success !== true) throw new Error(response.message);

  return response;
}

export async function getCompanySubscriptionsAPI(companyId: string): Promise<CompanySubscriptions[]> {
  const response = await API.get(`/api/company/${companyId}/subscriptions`);

  if (response.success !== true) throw new Error(response.message);

  return response.subscriptions;
}

export async function getPricingFromStripe(
  companyId: string,
  externalSubscriptionId: string,
): Promise<{
  pricePerQuantity: number;
  quantity: number;
  renewing: boolean;
}> {
  const reponse = await API.get(`/api/company/${companyId}/subscriptions/stripe/pricing/${externalSubscriptionId}`);

  if (reponse.success !== true) throw new Error(reponse.message);

  return reponse.pricing;
}

export async function cancelPlanFetch(companyId: string, subscriptionId: string, reason: string) {
  const response = await API.post(`/api/company/${companyId}/subscriptions/${subscriptionId}/cancel`, {
    body: JSON.stringify({ reason }),
  });

  if (response.success !== true) throw new Error(response.message);

  return response;
}

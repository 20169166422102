"use client";

import { Box, Collapse, HStack, Progress, Text, VStack } from "@chakra-ui/react";
import { Companies, Customizations, Users } from "@prisma/client";
import { isEmpty, startCase } from "lodash";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";

export function RenderFilters({
  company,
  usersPromise,
  availableSubcontractorsPromise,
  showFilters,
  setProjectTypeFilter,
  projectTypeFilter,
  setSubcontractorFilter,
  subcontractorFilter,
  setStatusFilter,
  statusFilter,
  ownersFilter,
  setOwnersFilter,
  assigneesFilter,
  setAssigneesFilter,
  creatorsFilter,
  setCreatorsFilter,
  customizations,
}: {
  availableSubcontractorsPromise: Promise<{ companies: Companies[] }>;
  usersPromise: Promise<{
    users: Users[];
    count: number;
  }>;
  showFilters: boolean;
  setOwnersFilter: (users: string[]) => void;
  setAssigneesFilter: (users: string[]) => void;
  setCreatorsFilter: (users: string[]) => void;
  setSubcontractorFilter: (subcontractors: string[]) => void;
  setStatusFilter: (statuses: string[]) => void;
  setProjectTypeFilter: (statuses: string[]) => void;
  statusFilter: string[];
  ownersFilter: string[];
  assigneesFilter: string[];
  creatorsFilter: string[];
  projectTypeFilter: string[];
  subcontractorFilter: string[];
  customizations: Customizations[];
  company: Companies;
}) {
  const [availableSubcontractors, setAvailableSubcontractors] = useState<Companies[]>([]);
  const [users, setuser] = useState<Users[]>([]);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      const subResponse = await availableSubcontractorsPromise;
      setAvailableSubcontractors(subResponse.companies);

      const { users: usersResponse } = await usersPromise;
      setuser(usersResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "completed", label: "Completed" },
    { value: "archived", label: "Archived" },
  ];

  const projectTypeOptions = [
    { value: "sld", label: "SLD" },
    { value: "siteWalk", label: "Site Walk" },
  ];

  const getSubcontractorNameById = (id: string) => {
    const subcontractor = availableSubcontractors.find((sub) => sub.id === id);
    return subcontractor?.name;
  };

  const getUserNameById = (id: string) => {
    const user = users.find((u) => u.id === id);

    return user ? `${user.firstName || ""} ${user.lastName || ""} (${user.email})` : "";
  };

  const getCustomizationNameById = (id: string) => {
    const customization = customizations.find((c) => c.id === id);
    return customization?.name;
  };

  const getUserSelectOptions = () => {
    const currentCompanyGroup = {
      label: company.name,
      options: users
        .filter((user) => user.companyId === company.id)
        .map((user) => ({ value: user.id, label: `${user.firstName || ""} ${user.lastName || ""} (${user.email})` })),
    };

    const otherCompanyGroups = availableSubcontractors.map((sub) => {
      return {
        label: sub.name,
        options: users
          .filter((user) => user.companyId === sub.id)
          .map((user) => ({ value: user.id, label: `${user.firstName || ""} ${user.lastName || ""} (${user.email})` })),
      };
    });

    return [currentCompanyGroup, ...otherCompanyGroups];
  };

  const customizationOptions = customizations.map((customization) => ({
    value: customization.id,
    label: startCase(customization.name!),
  }));

  return (
    <Collapse in={showFilters} animateOpacity style={{ overflow: "visible" }}>
      {loading ? (
        <div className="h-20 justify-center items-center pt-10">
          <Progress size="xs" isIndeterminate />
        </div>
      ) : (
        <Box p="5">
          <VStack alignItems="flex-start">
            <HStack mt={3} gap={5} alignItems="flex-start">
              <VStack alignItems="flex-start" maxW={400}>
                <Text fontSize="sm" fontWeight="bold">
                  Project Status
                </Text>
                <ReactSelect
                  value={statusFilter
                    .filter((status) => status !== "all")
                    .map((status) => ({ value: status, label: startCase(status) }))}
                  placeholder="Select statuses"
                  isMulti
                  options={statusOptions}
                  onChange={(statuses) => {
                    const newStatuses = statuses.map((s) => s.value);
                    setStatusFilter(isEmpty(newStatuses) ? ["all"] : newStatuses);
                  }}
                  styles={{
                    control: (baseStyles: any, state) => ({
                      ...baseStyles,
                      width: "300px",
                    }),
                  }}
                />
              </VStack>
              <VStack alignItems="flex-start" maxW={400}>
                <Text fontSize="sm" fontWeight="bold">
                  Project Type
                </Text>
                <ReactSelect
                  value={projectTypeFilter.map((customizationId) => ({
                    value: customizationId,
                    label: getCustomizationNameById(customizationId),
                  }))}
                  placeholder="Select type"
                  isMulti
                  options={customizationOptions}
                  onChange={(statuses) => {
                    setProjectTypeFilter(statuses.map((s) => s.value));
                  }}
                  styles={{
                    control: (baseStyles: any, state) => ({
                      ...baseStyles,
                      width: "300px",
                    }),
                  }}
                />
              </VStack>
              {availableSubcontractors.length > 0 ? (
                <VStack alignItems="flex-start" maxW={400}>
                  <Text fontSize="sm" fontWeight="bold">
                    Subcontractor
                  </Text>
                  <ReactSelect
                    value={subcontractorFilter.map((subCon) => ({
                      value: subCon,
                      label: getSubcontractorNameById(subCon),
                    }))}
                    placeholder="Select subcontractors"
                    isMulti
                    options={availableSubcontractors.map((subCon) => ({
                      value: subCon.id,
                      label: subCon.name,
                    }))}
                    onChange={(subs) => {
                      setSubcontractorFilter(subs.map((s) => s.value));
                    }}
                    styles={{
                      control: (baseStyles: any, state) => ({
                        ...baseStyles,
                        width: "300px",
                      }),
                    }}
                  />
                </VStack>
              ) : null}
            </HStack>
            <HStack mt={3} gap={5} alignItems="flex-start">
              <VStack alignItems="flex-start" maxW={400}>
                <Text fontSize="sm" fontWeight="bold">
                  Project Owner
                </Text>
                <ReactSelect
                  value={ownersFilter.map((user) => ({ value: user, label: getUserNameById(user) }))}
                  placeholder="Select users"
                  isMulti
                  //@ts-ignore
                  options={getUserSelectOptions()}
                  onChange={(userIds) => {
                    setOwnersFilter(userIds.map((userId) => userId.value));
                  }}
                  styles={{
                    container: (baseStyles: any, state) => ({
                      ...baseStyles,
                      width: "100%",
                    }),
                    control: (baseStyles: any, state) => ({
                      ...baseStyles,
                      width: "300px",
                      flex: 1,
                    }),
                  }}
                />
              </VStack>
              <VStack alignItems="flex-start" maxW={400}>
                <Text fontSize="sm" fontWeight="bold">
                  Assigned User
                </Text>
                <ReactSelect
                  value={assigneesFilter.map((user) => ({ value: user, label: getUserNameById(user) }))}
                  placeholder="Select users"
                  isMulti
                  //@ts-ignore
                  options={getUserSelectOptions()}
                  onChange={(userIds) => {
                    setAssigneesFilter(userIds.map((userId) => userId.value));
                  }}
                  styles={{
                    container: (baseStyles: any, state) => ({
                      ...baseStyles,
                      width: "100%",
                    }),
                    control: (baseStyles: any, state) => ({
                      ...baseStyles,
                      width: "300px",
                      flex: 1,
                    }),
                  }}
                />
              </VStack>
              <VStack alignItems="flex-start" maxW={400}>
                <Text fontSize="sm" fontWeight="bold">
                  Project Creator
                </Text>
                <ReactSelect
                  value={creatorsFilter.map((user) => ({ value: user, label: getUserNameById(user) }))}
                  placeholder="Select users"
                  isMulti
                  //@ts-ignore
                  options={getUserSelectOptions()}
                  onChange={(userIds) => {
                    setCreatorsFilter(userIds.map((userId) => userId.value));
                  }}
                  styles={{
                    container: (baseStyles: any, state) => ({
                      ...baseStyles,
                      width: "100%",
                    }),
                    control: (baseStyles: any, state) => ({
                      ...baseStyles,
                      width: "300px",
                      flex: 1,
                    }),
                  }}
                />
              </VStack>
            </HStack>
          </VStack>
        </Box>
      )}
    </Collapse>
  );
}

export default RenderFilters;

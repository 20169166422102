import { getFullAddress } from "@/components/Projects";
import { forEach, isNil } from "lodash";
import { useQueryState } from "next-usequerystate";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { createContext, useCallback, useContext, useEffect, useState, useTransition } from "react";
import useDebounce from "./useDebounce";

const PaginationContext = createContext({
  paginationState: {
    page: 1,
    limit: 10,
    sortKey: undefined as string | undefined,
    sortDirection: undefined as string | undefined,

    oldSearchParams: undefined as string | undefined,
  },
  updatePaginationState: (updates) => {},
});

export const PaginationProvider = ({ children }) => {
  const searchParams = useSearchParams();
  const [paginationState, setPaginationState] = useState({
    page: parseQueryParam(searchParams.get("page")) ?? 1,
    limit: parseQueryParam(searchParams.get("limit")) ?? 10,
    sortKey: searchParams.get("sortBy") ?? undefined,
    sortDirection: searchParams.get("dir") ?? undefined,
    oldSearchParams: undefined,
  });

  const updatePaginationState = useCallback((updates) => {
    setPaginationState((prevState) => ({ ...prevState, ...updates }));
  }, []);

  return (
    <PaginationContext.Provider value={{ paginationState, updatePaginationState }}>
      {children}
    </PaginationContext.Provider>
  );
};

export const useGlobalPaginationParams = () => {
  const context = useContext(PaginationContext);
  if (context === undefined) {
    throw new Error("useGlobalPaginationParams must be used within a PaginationProvider");
  }
  return context;
};

const parseQueryParam = (param) => parseInt(param, 10) || undefined;
export const parseArrayParam = (param) => {
  const parsedParam = (param?.split && param?.split(",")) || undefined;

  if (!isNil(parsedParam) && parsedParam.length === 1 && parsedParam[0] === "") {
    parsedParam.pop();
  }

  return parsedParam;
};

export const usePaginationParams = (serverPagination = false) => {
  const { paginationState, updatePaginationState } = useGlobalPaginationParams();
  const [page, setPage] = useQueryState("page", { defaultValue: paginationState.page.toString() });
  const [limit, setLimit] = useQueryState("limit", { defaultValue: paginationState.limit.toString() });
  const [sortKey, setSortKey] = useQueryState("sortBy", { defaultValue: paginationState.sortKey ?? "" });
  const [sortDirection, setSortDirection] = useQueryState("dir", { defaultValue: paginationState.sortDirection ?? "" });
  // const [search, setSearch] = useQueryState("search", { defaultValue: paginationState.search ?? "" });
  const [isPending, startTransition] = useTransition();

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const router = useRouter();
  const debouncedLoading = useDebounce(isPending, 100);

  useEffect(() => {
    return () => {
      resetPaginationParamsToDefaults();
    };
  }, [router]);

  useEffect(() => {
    if (paginationState.limit !== Number(limit)) {
      setPage("1");
    }
  }, [limit]);

  const resetPaginationParamsToDefaults = useCallback(() => {
    // router.replace(`${pathname}`);
    updatePaginationState({
      page: 1,
      limit: 10,
      sortKey: "",
      sortDirection: "",
    });
  }, [updatePaginationState]);

  const syncUrlWithGlobalState = () => {
    const { oldSearchParams } = paginationState;
    const queryParams = new URLSearchParams(searchParams);
    let hasChanged = false;

    const newParams = queryParams.toString();
    if (oldSearchParams !== newParams) {
      hasChanged = true;
    }

    if (serverPagination && hasChanged) {
      startTransition(() => {
        const paramsString = purgeEmptyQueryParams(queryParams).toString();
        updatePaginationState({ oldSearchParams: paramsString });
        router.replace(`${pathname}?${paramsString}`, { scroll: false });
        // router.refresh();
      });
    }
  };

  useEffect(() => {
    const page = parseQueryParam(searchParams.get("page"));
    const limit = parseQueryParam(searchParams.get("limit"));
    const sortKey = searchParams.get("sortBy");
    const sortDirection = searchParams.get("dir");
    const search = searchParams.get("search");

    const newPaginationState = {
      page: page || paginationState.page,
      limit: limit || paginationState.limit,
      sortKey: sortKey || paginationState.sortKey,
      sortDirection: sortDirection || paginationState.sortDirection,
    };

    updatePaginationState(newPaginationState);
    syncUrlWithGlobalState();
  }, [searchParams]);

  // useEffect(() => {
  //   syncUrlWithGlobalState();
  // }, [paginationState]);

  const purgeEmptyQueryParams = (params: URLSearchParams) => {
    const cleanedParams = new URLSearchParams();

    params.forEach((value, key) => {
      if (!isNil(value) && value?.trim() !== "") {
        // Add non-empty parameter to the cleanedParams
        cleanedParams.append(key, value);
      }
    });

    return cleanedParams;
  };

  function updateParam(params: URLSearchParams, param: string, value: string | null | undefined) {
    if (!isNil(value) && value !== "") {
      params.set(param, value);
    } else {
      params.delete(param);
    }
  }

  const paginateFilteredData = useCallback(
    (data) => {
      return data.slice(
        (paginationState.page - 1) * paginationState.limit,
        paginationState.page * paginationState.limit,
      );
    },
    [paginationState],
  );

  const getNumberOfPages = useCallback(
    (data: Array<any> | Number) => {
      let length;
      if (Array.isArray(data)) {
        length = data.length;
      } else {
        length = data;
      }
      return Math.ceil(length / paginationState.limit);
    },
    [paginationState],
  );

  const filterBySearch = (projects, columnsIn) => {
    const query = searchParams.get("search")?.toLowerCase() ?? "";
    const searchKeys = columnsIn.filter((column) => column.searchable).map((column) => column.name);
    let filteredData = [...projects];
    let isFilteringSystems = false;
    if (searchParams.get("search")) {
      filteredData = filteredData.filter((row) => {
        const matchingQuery = searchKeys?.some((key) => {
          const value = row[key];
          if (typeof value === "string") {
            return value.toLowerCase().includes(query);
          }
          return false;
        });

        if (row.__typename === "System") {
          isFilteringSystems = true;
          return matchingQuery || getFullAddress(row).toLowerCase().includes(query);
        }

        return matchingQuery;
      });
      if (isFilteringSystems) {
        filteredData = [...filteredData].sort((a, b) => {
          const checkA = a.name.toLowerCase().startsWith(query);
          const checkB = b.name.toLowerCase().startsWith(query);
          if (checkA && !checkB) {
            return -1;
          } else if (checkB && !checkA) {
            return 1;
          }
          return 0;
        });
      }
    }

    return filteredData;
  };

  return {
    ...paginationState,
    setPage,
    setLimit,
    setSortKey,
    setSortDirection,
    filterBySearch,
    getNumberOfPages,
    updateParam,
    paginateFilteredData,
    searchParams: new URLSearchParams(searchParams.toString()),
    loading: debouncedLoading,
  };
};
